<i18n>
  {
    "en": {
      "security": "Security",
      "security-description": "Activate IP filter for access and login on wepipe.",
      "enable": "Enable",
      "enabled": "Enabled!",
      "enabled-ips": "Enabled IPs",
      "save": "Save",
      "tracker": "Tracker",
      "tracker-description": "Activate wepipe card tracker portal."
    },
    "pt-BR": {
      "security": "Segurança",
      "security-description": "Ativar filtro por IP para logar e acessar a wepipe.",
      "enable": "Habilitar",
      "enabled": "Habilitado!",
      "enabled-ips": "Lista de IPs permitidos",
      "save": "Salvar",
      "tracker": "Rastreador",
      "tracker-description": "Ativar portal acompanhamento de cards abertos."
    }
  }
</i18n>

<template>
  <v-form ref="form">
    <we-loading-overflow class="small" :loading="loading" />
    <we-block
      v-if="customer"
      :active="customer.is_ip_filter_enabled"
      class="toggle"
      :title="$t('security')"
      :description="$t('security-description')"
    >
      <template #top>
        <v-switch
          v-model="customer.is_ip_filter_enabled"
          :label="customer.is_ip_filter_enabled ? $t('enabled') : $t('enable')"
          hide-details
        />
      </template>
      <template #sections>
        <div class="block-section">
          <we-input
            type="tags"
            :label="$t('enabled-ips')"
            :clearable="false"
            :items="customer.ip_list"
            v-model="customer.ip_list"
          />
        </div>
      </template>
    </we-block>
    <we-block
      v-if="customer"
      :active="customer.is_ip_filter_enabled"
      class="toggle"
      :title="$t('tracker')"
      :description="$t('tracker-description')"
    >
      <template #top>
        <v-switch
          v-model="customer.is_card_tracker_enabled"
          :label="
            customer.is_card_tracker_enabled ? $t('enabled') : $t('enable')
          "
          hide-details
        />
      </template>
    </we-block>
    <we-button
      @click="updateCustomer(customer)"
      class="green"
      :text="$t('save')"
      icon="check"
      :loading="btnLoading"
    />
  </v-form>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      loading: true,
      btnLoading: false
    };
  },
  computed: {
    customer() {
      return this.$store.getters.getCustomer;
    }
  },
  methods: {
    ...mapActions(["customerRequest", "updateCustomerRequest"]),
    async updateCustomer(customer) {
      const validated = this.$refs.form.validate();

      if (validated) {
        this.btnLoading = true;

        let payload = { ...customer };
        delete payload.domain;
        delete payload.subscription_plan;

        await this.updateCustomerRequest({
          ...payload
        });
        this.btnLoading = false;
      }
    }
  },
  async mounted() {
    await this.customerRequest();
    this.loading = false;
  }
};
</script>
