<i18n>
  {
    "en": {
      "brand": "Brand",
      "brand-description": "Customize the system with your company brand.",
      "themes": "Themes",
      "brand-info": "Upload your brand with transparent background and preferably SVG to maintain quality"
    },
    "pt-BR": {
      "brand": "Marca",
      "brand-description": "Customize o sistema com a logomarca da sua empresa.",
      "themes": "Temas",
      "brand-info": "Faça upload da marca com fundo transparente e de preferência em SVG para manter a qualidade"
    }
  }
  </i18n>

<template>
  <div id="profile_customization">
    <we-block
      :title="$t('brand')"
      :description="$t('brand-description')"
      v-if="isAdmin"
    >
      <template #sections>
        <div class="block-section">
          <we-file-upload
            :info="$t('brand-info')"
            :file="customer.brand"
            :loading="imgLoading"
            @change="onFileChange"
            accept=".svg,image/*"
          />
        </div>
      </template>
    </we-block>
    <we-block :title="$t('themes')">
      <template #sections>
        <div class="block-section grid">
          <div
            class="theme"
            @click="changeTheme('light')"
            :class="{ active: !$vuetify.theme.dark }"
          >
            <picture class="picture">
              <img src="@/assets/themes/light.svg" alt="Light theme" />
            </picture>
            <div class="theme-name">
              Light
            </div>
          </div>
          <div
            class="theme"
            @click="changeTheme('dark')"
            :class="{ active: $vuetify.theme.dark }"
          >
            <picture class="picture">
              <img src="@/assets/themes/dark.svg" alt="Dark theme" />
            </picture>
            <div class="theme-name">
              Dark
            </div>
          </div>
        </div>
      </template>
    </we-block>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      imgLoading: true
    };
  },
  computed: {
    customer() {
      return this.$store.getters.getCustomer;
    },
    isAdmin() {
      return this.$store.getters?.getLoggedUserIsAdmin;
    }
  },
  methods: {
    ...mapActions([
      "customerRequest",
      "updateCustomerRequest",
      "updateCustomerBrandRequest"
    ]),
    changeTheme(theme) {
      this.$vuetify.theme.dark = theme === "dark";
      localStorage.setItem("theme", theme);
    },
    async onFileChange(file) {
      this.picture = file;
      this.imgLoading = true;
      await this.updateCustomerBrandRequest(this.picture);
      this.imgLoading = false;
    }
  },
  async mounted() {
    await this.customerRequest();
    this.imgLoading = false;
  }
};
</script>
