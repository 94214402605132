<i18n>
{
  "en": {
    "button-1": "Plan upgrade",
    "button-2": "Company account",
    "button-3": "Change password",
    "button-4": "Logout",
    "profile": "Profile",
    "tutorials": "Tutorials",
    "customization": "Customization",
    "security": "Security",
    "domain": "Domain",
    "version": "Version"
  },
  "pt-BR": {
    "button-1": "Upgrade de plano",
    "button-2": "Conta da empresa",
    "button-3": "Alterar senha",
    "button-4": "Sair da conta",
    "profile": "Perfil",
    "tutorials": "Tutoriais",
    "customization": "Customização",
    "security": "Segurança",
    "domain": "Domínio",
    "version": "Versão"
  }
}
</i18n>

<template>
  <div id="profile_page">
    <customer v-if="showCustomer" @close="showCustomer = false" />
    <change-password
      v-if="showChangePassword"
      @close="showChangePassword = false"
    />
    <board-plugin
      @selectMenu="menuActiveIndex = $event"
      :activeMenu="menuActiveIndex"
      :menu="enabledMenus"
    >
      <template #content>
        <div v-if="menuActiveIndex === 0" class="top-card">
          <div class="card-content">
            <we-picture-change
              size="90"
              :name="loggedUser.first_name"
              :picture="loggedUser.picture"
              :loading="imgLoading"
              @onFileChange="onFileChange"
              @delete="deleteUserImage(loggedUser.id)"
            />
            <div class="data">
              <we-text-tooltip
                class="data-item name"
                :text="`${loggedUser.first_name} ${loggedUser.last_name}`"
              />
              <we-text-tooltip
                class="data-item email"
                :text="loggedUser.email"
              />
            </div>
          </div>
          <div class="card-bottom">
            <div class="customer-details">
              <div class="item domain">
                <span class="item-label"> {{ $t("domain") }}: </span>
                <b>{{ domain }}</b>
              </div>
              <div class="item version">
                <span class="item-label"> {{ $t("version") }}: </span>
                <b>{{ version }}</b>
              </div>
            </div>
            <we-privacy-policy class="big" />
          </div>
        </div>
        <we-install-pwa v-if="menuActiveIndex === 0" />
        <div class="profile-actions" v-if="menuActiveIndex === 0">
          <we-button
            v-if="isAdmin"
            class="primary fill only-desktop"
            :text="$t('button-1')"
            icon="rocket"
            @click="$store.commit('openUpgradePlan')"
          />
          <we-button
            v-if="isAdmin"
            class="disabled fill"
            :text="$t('button-2')"
            icon="user-circle"
            @click="showCustomer = true"
          />
          <we-button
            @click="showChangePassword = true"
            class="disabled fill"
            :text="$t('button-3')"
            icon="key"
          />
          <we-button
            @click="logout"
            class="disabled fill"
            :text="$t('button-4')"
            icon="sign-out-alt"
          />
        </div>
        <profile-tutorials v-if="menuActiveIndex === 1" />
        <profile-customization v-if="menuActiveIndex === 2" />
        <profile-security v-if="isAdmin && menuActiveIndex === 3" />
        <profile-invoices v-if="isAdmin && menuActiveIndex === 9999" />
      </template>
    </board-plugin>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Customer from "@/views/Admin/Customer/Index.vue";
import ChangePassword from "@/views/Global/ChangePassword.vue";
import ProfileInvoices from "@/views/Profile/Invoices.vue";
import ProfileTutorials from "@/views/Profile/Tutorials.vue";
import ProfileCustomization from "@/views/Profile/Customization.vue";
import ProfileSecurity from "@/views/Profile/Security.vue";
export default {
  components: {
    Customer,
    ChangePassword,
    ProfileInvoices,
    ProfileTutorials,
    ProfileCustomization,
    ProfileSecurity
  },
  data() {
    return {
      showCustomer: false,
      showChangePassword: false,
      picture: {},
      imgLoading: false,
      menuActiveIndex: 0
    };
  },
  computed: {
    enabledMenus() {
      return this.menu.filter(e => e.enabled);
    },
    menu() {
      return [
        {
          name: this.$t("profile"),
          icon: "user-circle",
          enabled: true
        },
        {
          name: this.$t("tutorials"),
          icon: "book",
          enabled: true
        },
        {
          name: this.$t("customization"),
          icon: "brush",
          enabled: true
        },
        {
          name: this.$t("security"),
          icon: "key",
          enabled: this.isAdmin
        }
      ];
    },
    loggedUser() {
      return this.$store.getters?.getLoggedUser;
    },
    isAdmin() {
      return this.$store.getters?.getLoggedUserIsAdmin;
    },
    domain() {
      return this.$store.getters.getDomain;
    },
    version() {
      return process?.env?.VUE_APP_VERSION || "0";
    }
  },
  methods: {
    ...mapActions(["updateUserPictureRequest", "deleteUserPictureRequest"]),
    async onFileChange(e) {
      const file = e.target.files[0];
      this.picture = file;
      this.imgLoading = true;

      const payload = {
        picture: this.picture,
        id: this.loggedUser.id
      };

      const resp = await this.updateUserPictureRequest(payload);
      if (resp.data) {
        this.$store.commit("setLoggedUser", resp.data);
      }

      this.imgLoading = false;
    },
    async deleteUserImage(id) {
      this.imgLoading = true;

      const resp = await this.deleteUserPictureRequest(id);
      if (resp.data) {
        this.$store.commit("setLoggedUser", resp.data);
      }

      this.imgLoading = false;
    },
    async logout() {
      await this.$store.dispatch("authLogout");
      this.$router.push({
        name: "login",
        params: { company: this.$store.getters.getDomain }
      });
    }
  }
};
</script>

<style lang="scss">
#profile_page {
  padding-top: 80px;
  @include mobile {
    padding-top: 0;
    height: 100%;
  }

  .board-plugin {
    .nav-and-content {
      max-width: 849px;
    }
  }

  .top-card {
    padding: 15px 20px;
    border-radius: 8px;
    border: 1px solid var(--line);
    margin-bottom: 24px;

    @include mobile {
      padding: 0;
      padding-bottom: 18px;
      border: none;
      border-bottom: 1px solid var(--line);
      border-radius: 0;
    }
    .card-content {
      display: grid;
      grid-template-columns: 90px minmax(0, 1fr);
      gap: 18px;
      align-items: center;
      margin-bottom: 20px;

      .data {
        font-weight: $medium;
        color: var(--text-1);
        font-size: 16px;

        .email {
          font-size: 14px;
          font-weight: $regular;
          color: var(--text-2);
        }
      }
    }

    .card-bottom {
      .customer-details {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: var(--text-2);
        margin-bottom: 12px;

        .item {
          margin: 0 8px;
        }

        b {
          margin-left: 4px;
          font-weight: $semibold;
        }
      }
    }
  }

  .profile-actions {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
    gap: 12px;
    margin-bottom: 24px;
  }

  .we-block {
    margin-bottom: 24px;
  }

  .theme {
    border-radius: 5px;
    border: 1px solid var(--line);
    overflow: hidden;
    cursor: pointer;

    &:hover {
      border-color: var(--text-3);

      .picture {
        border-color: var(--text-3);
      }
    }

    &.active {
      border-color: var(--primary);
      background: var(--primary-bg);

      .picture {
        border-color: var(--primary);
      }
    }

    .picture {
      display: block;
      border-bottom: 1px solid var(--line);

      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }

    .theme-name {
      padding: 15px;
      font-size: 14px;
      font-weight: $medium;
      color: (--text-2);

      @include mobile {
        padding: 8px 15px;
      }
    }
  }
}
</style>
