<i18n>
  {
    "en": {
      "tutorials": "Tutorials",
      "learn": "Learn how to use",
      "get-help": "Get help"
    },
    "pt-BR": {
      "tutorials": "Tutoriais",
      "learn": "Aprendar a usar o sistema",
      "get-help": "Consiga ajuda"
    }
  }
  </i18n>

<template>
  <we-block :title="$t('tutorials')">
    <template #sections>
      <div class="block-section">
        <we-input type="div" :placeholder="$t('learn')" />
      </div>
      <div class="block-section grid">
        <a
          v-for="item in learningItems"
          :key="item.name"
          target="_blank"
          :href="item.url"
        >
          <we-card-icon
            class="button"
            color="primary"
            :icon="item.icon"
            :label="item.label"
            :title="item.name"
          >
            <template #sublabel>
              <font-awesome-icon icon="play" />
              {{ item.time }}
            </template>
          </we-card-icon>
        </a>
      </div>
      <div class="block-section">
        <we-input type="div" :placeholder="$t('get-help')" />
      </div>
      <div class="block-section grid">
        <a
          v-for="item in helpItems"
          :key="item.name"
          target="_blank"
          :href="item.url"
        >
          <we-card-icon
            class="button"
            :icon="item.icon"
            :label="item.label"
            :title="item.name"
          />
        </a>
      </div>
    </template>
  </we-block>
</template>

<script>
export default {
  computed: {
    learningItems() {
      return this.$store.getters.getLearningItems;
    },
    helpItems() {
      return this.$store.getters.getHelpItems;
    }
  }
};
</script>
